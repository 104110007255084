import React from "react";
import PropTypes from "prop-types";

const divStyle = {
  // background: 'yellow',
  //
  // top: '200',
  // bottom: 'auto',
  // left: '200',
  // right: 'auto',
  // border: '1px solid #ccc',
  // opacity: '1',
  // visibility: 'visible',

  background: 'white',
  border: '1px solid #ccc',
  opacity: '1',
  visibility: 'visible',
  position: 'absolute',
  left: 100,
  top: 100,
  right: 100,
  height: 500,
  //zIndex: 1,
  // backgroundColor: 'white',
};

export default class Modal extends React.Component {
  //
  // onClose = e => {
  //   this.props.onClose && this.props.onClose(e);
  // };

  render() {
    if (!this.props.show) {
      return null;
    }

    return (
      <div className="fixed-top">
      <div style={divStyle}>
        <div>{this.props.children}</div>
      </div>
      </div>
    );
  }
}

Modal.propTypes = {
//  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};
