import React from 'react';
import Select from 'react-select';
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
//import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

import Datetime from 'react-datetime'
import Moment from 'react-moment';
//import 'moment-timezone';
import '../styles/Application.css';
import Modal from './Modal'

class Comprobante extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      concepto: '',

      fechaDocumento: new Date(),

      plantilla: this.props.plantillaHelper,
      selectedOptions: [],
      tableHeader: ['Concepto', 'Cuenta', 'Columna', 'Importe'],
    }
  }

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value});
  }

  handleChangeCuenta = (selectedOption, lineIndex) => {
    let selectedOptions = this.state.selectedOptions
    selectedOptions[lineIndex] = selectedOption
    // this.setState(
    //   { selectedOptions },() => console.log(`Option selected:`, this.state.selectedOptions[lineIndex])
    // );

    this.setState({selectedOptions})

    //////////////////////////////////////////////////////

    let plantilla = this.state.plantilla;
    const lineas = plantilla.lineas.slice();

    let newLineas = []
    lineas.map((x, index) => {
      let newIdCuenta = x.id_cuenta

      if(lineIndex === index) {
        newIdCuenta = selectedOption.value;
      }

      let registro = { concepto: x.concepto, importe: x.importe, cuentas: x.cuentas, id_cuenta: newIdCuenta, columna: x.columna }
      newLineas.push(registro)
    })
    plantilla.lineas = newLineas.slice();
    this.setState({ plantilla: plantilla });

    ////////////////////////////////////////////////////////////
  };

  renderCabezal() {
    return(
      <div>

        <div className="row">

          <div className="col-md-3">
            <div className="form-group">
              <label>Tipo de Documento</label>
              <div className="row">
                <div className="col-md-12">
                  <Select
                    value={this.state.selectedTipoDocumentoOption}
                    onChange={this.handleChangeTipoDocumento}
                    options={this.state.tiposDocumentosOptions}
                    placeholder={"Tipo Documento"}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-1">
            <div className="form-group">
              <label>Serie</label>
              <div className="row">
                <div className="col-md-12">
                  <input type="text" name="serieDocumento"
                    value={this.state.serieDocumento}
                    onChange={this.handleChange}
                    className="form-control" />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-2">
            <div className="form-group">
              <label>Numero</label>
              <div className="row">
                <div className="col-md-12">
                  <input type="text" name="numeroDocumento"
                    value={this.state.numeroDocumento}
                    onChange={this.handleChange}
                    className="form-control" />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label>Fecha Doc.</label>
              <Datetime
                value={this.state.fecha} name="fecha"
                closeOnSelect={true}
                ref={(x) => {this.dateTime = x}} onChange={this.handleChangeFecha}
                dateFormat={"DD/MM/YYYY"} timeFormat={false} input={true}
                inputProps={{name: 'fechaDocumento', ref: this.storeRef, onKeyPress: this.handleKeyPress}}
                />
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label>Fecha Venc.</label>
              <Datetime
                value={this.state.fecha} name="fecha"
                closeOnSelect={true}
                ref={(x) => {this.dateTime = x}} onChange={this.handleChangeFecha}
                dateFormat={"DD/MM/YYYY"} timeFormat={false} input={true}
                inputProps={{name: 'fecha', ref: this.storeRef, onKeyPress: this.handleKeyPress}}
                />
            </div>
          </div>

        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label>Contacto</label>
              <div className="row">
                <div className="col-md-12">
                  <Select
                    value={this.state.selectedContactoOption}
                    onChange={this.handleChangeContacto}
                    options={this.state.contactosOptions}
                    placeholder={"Contacto"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }

  renderTableHeader() {
    let header = this.state.tableHeader
    return header.map((key, index) => {
      return <th key={index}>{key}</th>
   })
  }

  renderTableData() {

  //  console.log(this.state.plantilla.lineas)

    return this.state.plantilla.lineas.map((linea, index) => {
      const { concepto, cuentas, columna } = linea //destructuring
      return (
         <tr key={index}>
            <td>{concepto}</td>

            <td>
            {<Select value={this.state.selectedOptions[index]}
              placeholder={"Cuenta"}
              onChange={(e) => this.handleChangeCuenta(e, index)}
              options={cuentas}/>
            }</td>

            <td>{columna}</td>
            <td>{
              <input type="text" name="importe" ref={this.storeRef}
                   onChange={(e) => this.handleChangeImporte(e, index)} />
              }
            </td>
         </tr>
      )
    })
  }

  handleChangeImporte = (event, lineIndex) => {
  //  console.log("event", event.target.value, "index", i)

    let plantilla = this.state.plantilla;
    const lineas = plantilla.lineas.slice();

    let newLineas = []
    lineas.map((x, index) => {

      let newImporte = x.importe
      if(lineIndex === index) {
        newImporte = event.target.value
      }

      let registro = { concepto: x.concepto, cuentas: x.cuentas, importe: newImporte, id_cuenta: x.id_cuenta, columna: x.columna }
      newLineas.push(registro)
    })
    plantilla.lineas = newLineas.slice();
    this.setState({ plantilla: plantilla });
  }

  handleGenerarAsiento = () => {
    let totalDebe = 0;
    let totalHaber = 0;
    let fechaDocumento = this.state.fechaDocumento;

    this.state.plantilla.lineas.map((x, index) => {
      if(x.columna == "DEBE") {
        totalDebe += x.importe;
      } else {
        totalHaber += x.importe;
      }
    })

    this.sendData(this.state.plantilla, totalDebe, totalHaber, fechaDocumento);
    this.handleCloseModal()
  }

  sendData = (plantilla, totalDebe, totalHaber, fechaDocumento) => {
    axios.post('/api/v1/comprobantes/generar_asiento',{
      data: {
        fecha: this.props.fecha,
        id_empresa: this.props.idEmpresa,
        total_debe: totalDebe,
        total_haber: totalHaber,
        plantilla: plantilla
      }
    }).then(response => {
        this.setState({

        })
    }).catch(e => {
        console.log(e);
    });
  }

  /////////////////////// Modal stuff //////////////////////////

  handleShowModal = () => {
    this.setState({ showModal: true });
  }

  handleCloseModal = () => {
    this.setState({ showModal: false });
  }

  renderModal() {
    return (
      <Modal show={this.state.showModal} onClose={this.handleCloseModa}>
        <div>
          {this.renderCabezal()}
          <Table striped bordered size="sm">
            <tbody>
               <tr>{this.renderTableHeader()}</tr>
               {this.renderTableData()}
            </tbody>
          </Table>
        </div>
          <Button variant="secondary" onClick={this.handleCloseModal}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={this.handleGenerarAsiento}>
            Guardar Cambios
         </Button>
      </Modal>
    )
  }


  //////////////////////////////////////////////////////////////

  render() {
    return (
      <div>
        <Button variant="primary" onClick={this.handleShowModal}>Agregar Comprobante</Button>
        <div dangerouslySetInnerHTML={{__html: '&nbsp;'}} />

        <Table striped bordered size="sm">
        <tbody>
          {this.renderModal()}
        </tbody>
        </Table>
      </div>
    )
  }
}

export default Comprobante;
