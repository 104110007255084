import React from "react"
import axios from 'axios';
import Select from 'react-select';
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'

//import Modal from 'react-bootstrap/Modal';
import Modal from './Modal'
import '../styles/Application.css'


class LineasAsiento extends React.Component {

  constructor(props) {
    super(props)

    let cuentas = []
    const tmpCuentas = props.dataCuentas.slice();
    tmpCuentas.map((x, index) => {
      let registro = { value: x, label: x.codigo + ' - ' + x.nombre }
      cuentas.push(registro)
    })

    let monedas = []
    const tmpMonedas = props.dataMonedas.slice();
    tmpMonedas.map((x, index) => {
      let registro = { value: x, label: x.nombre }
      monedas.push(registro)
    })

    let centrosCostos = []
    const tmpCentrosCostos = props.dataCentrosCostos.slice();
    tmpCentrosCostos.map((x, index) => {
      let registro = { value: x, label: x.nombre }
      centrosCostos.push(registro)
    })

    let proyectos = []
    const tmpProyectos = props.dataProyectos.slice();
    tmpProyectos.map((x, index) => {
      let registro = { value: x, label: x.nombre }
      proyectos.push(registro)
    })

    let locales = []
    const tmpLocales = props.dataLocales.slice();
    tmpLocales.map((x, index) => {
      let registro = { value: x, label: x.nombre }
      locales.push(registro)
    })

    let contactos = []
    const tmpContactos = props.dataContactos.slice();
    tmpContactos.map((x, index) => {
      let registro = { value: x, label: x.nombre }
      contactos.push(registro)
    })

    this.initState(cuentas, monedas, centrosCostos, proyectos, locales, contactos)
  }

  componentDidMount() {

  }

  initState(cuentas, monedas, centrosCostos, proyectos, locales, contactos) {
    const state = {
      numeroAsiento: null,
      showModalLinea: false,
      showModalError: false,
      showModalErroresLinea: false,
      showModalCierreAsiento: false,
      lineas: [],
      debe: '',
      haber: '',
      concepto: '',
      cotizacion: 0,

      totalDebeAsiento: 0,
      totalHaberAsiento: 0,

      cuentasOptions: cuentas,
      selectedCuentaOption: null,
      monedaCuenta: 0,

      monedasOptions: monedas,
      selectedMonedaOption: null,

      centrosCostosOptions: centrosCostos,
      selectedCentroCostosOption: null,
      disableCentroCostos: false,

      proyectosOptions: proyectos,
      selectedProyectoOption: null,

      localesOptions: locales,
      selectedLocalOption: null,

      contactosOptions: contactos,
      selectedContactoOption: null,

      textosError: [],
      textosErroresLinea: [],
      idAsiento: null,
      idLogAsiento: null,
    };
    this.state = state;
  }

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value});
  }

  /////////////////////// Modal stuff //////////////////////////

  handleShowModalLinea = () => {
    this.setState({ showModalLinea: true });
  }

  handleCloseModalLinea = () => {
    this.setState({ showModalLinea: false });
  }

  handleShowModalError = () => {
    this.setState({ showModalError: true });
  }

  handleCloseModalError = () => {
    this.setState({ showModalError: false });
  }

  handleCloseModalErroresLinea = () => {
    this.setState({ showModalErroresLinea: false });
  }

  handleCloseModalCierreAsiento = () => {
    this.setState({ showModalCierreAsiento: false });
  }

  //////////////////////////////////////////////////////////////

  handleChangeCuenta = (selectedCuentaOption) => {

  //////  setea centro de costos y moneda ////////////////////
    let disableCentroCostos = false

    if(selectedCuentaOption.value.centro_costos == "N") {
      disableCentroCostos = true
    }

    let monedaCuenta = 2

    this.setState({
      selectedCuentaOption: selectedCuentaOption,
      disableCentroCostos: disableCentroCostos,
      monedaCuenta: monedaCuenta
    });
  }

  handleChangeCentroCostos = (selectedCentroCostosOption) => {
    this.setState({ selectedCentroCostosOption });
  }

  handleChangeProyecto = (selectedProyectoOption) => {
    this.setState({ selectedProyectoOption });
  }

  handleChangeCentroCostos = (selectedCentroCostosOption) => {
    this.setState({ selectedCentroCostosOption });
  }

  handleChangeLocal = (selectedLocalOption) => {
    this.setState({ selectedLocalOption });
  }

  handleChangeContacto = (selectedContactoOption) => {
    this.setState({ selectedContactoOption });
  }

  handleChangeMoneda = (selectedMonedaOption) => {
// refrescar cotizacion
    this.setState({ selectedMonedaOption: selectedMonedaOption });
    const fecha = new Date(this.props.anio+"-"+this.props.mes+"-"+this.props.dia)
    const moneda = selectedMonedaOption.value.id;
    this.getCotizacion(fecha, moneda)
  }

  getCotizacion = (fecha, moneda) => {
    const strFecha = fecha.getFullYear() + "-" + (fecha.getMonth() + 1) + "-" + fecha.getDate()
    axios({
      method: 'get',
      url: '/api/v1/monedas/get_cotizacion?moneda='+moneda+'&fecha='+strFecha,
      responseType: 'json'
    }).then(response => {
        this.setState({cotizacion: response.data.fiscal})
    }).catch(e => {
        console.log(e);
    });
  }

  handleAgregarLinea = () => { // agrega una linea si el cabezal esta ok
    let isOk = true
    let textosError = []
    let moneda = 0
    let cotizacion = 0

    if(this.props.moneda == null) {
      isOk = false
      textosError.push('No se ha seleccionado Moneda')
      this.setState({textosError: textosError})
    } else {
      moneda = this.props.moneda.value.id
      cotizacion = this.props.cotizacionAsiento
    }

    if(this.props.tipoAsiento == null) {
      isOk = false
      textosError.push('No se ha seleccionado Tipo de Asiento')
      this.setState({textosError: textosError})
    }

    if(this.props.contactoAsiento == null) {
      isOk = false
      textosError.push('No se ha seleccionado Contacto')
      this.setState({textosError: textosError})
    }

    if(moneda != 1 && cotizacion == 0) {
      isOk = false
      textosError.push('No se ha cargado cotizacion')
      this.setState({textosError: textosError})
    }

    if(isOk)
    {
      if(this.state.idAsiento == null) {
        this.createAsiento()
        this.createLogAsiento()
      }
      this.handleShowModalLinea()
    } else {
      this.handleShowModalError()
    }
  }

  handleDelete = (idLineaAsiento) => {
    this.deleteLine(idLineaAsiento)
    this.deleteLineaAsiento(idLineaAsiento)
  }

  handleSaveLinea = (index) => {
    const debe = this.state.debe
    const haber = this.state.haber

    let textosErroresLinea = []

    let idCentroCostos = 0
    let nombreCentroCostos = ''
    let idProyecto = 0
    let nombreProyecto = ''
    let idLocal = 0
    let nombreLocal = ''
    let idContacto = 0
    let nombreContacto = ''

    if(this.state.selectedCentroCostosOption == null) {
      idCentroCostos = 0
      nombreCentroCostos = ''
    } else {
      idCentroCostos = this.state.selectedCentroCostosOption.value.id
      nombreCentroCostos = this.state.selectedCentroCostosOption.value.nombre
    }

    if(this.state.selectedProyectoOption == null) {
      idProyecto = 0
      nombreProyecto = ''
    } else {
      idProyecto = this.state.selectedProyectoOption.value.id
      nombreProyecto = this.state.selectedProyectoOption.value.nombre
    }

    if(this.state.selectedLocalOption == null) {
      idLocal = 0
      nombreLocal = ''
    } else {
      idLocal = this.state.selectedLocalOption.value.id
      nombreLocal = this.state.selectedLocalOption.value.nombre
    }

    if(!this.state.selectedContactoOption) {
      idContacto = 0
      nombreContacto = ''
    } else {
      idContacto = this.state.selectedContactoOption.value.id
      nombreContacto = this.state.selectedContactoOption.value.nombre
    }

// validacion de los campos de la linea
    this.setState({showModalErroresLinea: false})
    let isOk = true
// cuenta
    if(this.state.selectedCuentaOption == null) {
      textosErroresLinea.push('No se ha seleccionado Cuenta')
      this.setState({textosErroresLinea: textosErroresLinea})
      isOk = false
    }
// importe
    if(debe == 0 && haber == 0) {
      textosErroresLinea.push('No se ha ingresado importe')
      this.setState({textosErroresLinea: textosErroresLinea})
      isOk = false
    }

    if(isOk) {
      this.handleCloseModalLinea();
      this.addLineaAsiento(debe, haber, idCentroCostos, nombreCentroCostos, idProyecto, nombreProyecto, idLocal, nombreLocal)
    } else {
      this.setState({showModalErroresLinea: true})
    }
  }

  handleSaveAsiento = () => {

    const lineas = this.state.lineas

    if (lineas && lineas.length) {    // not empty
      let hayErrores = false

      let total_debe = 0
      let total_haber = 0

      lineas.map((linea, index) => {

        total_debe += linea.debe
        total_haber += linea.haber
      })

      // if( (total_debe - total_haber) != 0.0) {
      //   hayErrores = true
      // }

      this.setState({
        showModalCierreAsiento: hayErrores,
        totalDebeAsiento: total_debe,
        totalHaberAsiento: total_haber
      })

      const id_asiento = this.state.idAsiento
      const guardar = 1
      this.cerrarAsiento(id_asiento, guardar)
      this.createLogAsiento()    
    }
  }

  handleCerrarSinGuardarAsiento = () => {
  //  this.props.updateAsiento(this.props.idAsiento)
    const id_asiento = this.state.idAsiento
    const guardar = 0

    this.cerrarAsiento(id_asiento, guardar)
  }

////////////////////////////////////////////////////////////////////////////////

  deleteLine(idLineaAsiento){
    let newLineas = this.state.lineas.filter((linea) => linea.idLineaAsiento !== idLineaAsiento)
    this.setState({
      lineas: newLineas
    })
  }

  addItem(idLineaAsiento, nombreCuenta, debe, haber, concepto, centroCostos, proyecto, local, contacto, moneda, cotizacion) {
    let newItem =  {idLineaAsiento: idLineaAsiento, nombreCuenta: nombreCuenta, debe: debe, haber: haber,
      concepto: concepto, centroCostos: centroCostos, proyecto: proyecto, local: local,
      contacto: contacto, moneda: moneda, cotizacion: cotizacion }
    let newLines = this.state.lineas
    newLines.push(newItem)
    this.setState({lineas: newLines, debe: '', haber: '', concepto: '', cotizacion: ''});
  }

///////////////////////////////// AXIOS ////////////////////////////////////////

  createAsiento = () => {

    let tipoDocumento = 0
    if(this.props.tipoDocumento) { // true if not null
      tipoDocumento = this.props.tipoDocumento.value.id
    }

    let idContacto = 0
    if (this.props.contactoAsiento) { // true if not null
      idContacto = this.props.contactoAsiento.value.id
    }

    const fecha = this.props.anio+"-"+this.props.mes+"-"+this.props.dia;

    axios.post('/api/v1/temp_asientos',{
      temp_asiento: {
        fecha: fecha,
        concepto: this.props.concepto,
        id_tipo_asiento: this.props.tipoAsiento.value.id,
        id_moneda: this.props.moneda.value.id,
        cotizacion: this.props.cotizacionAsiento,
        tipo_documento: tipoDocumento,
        serie_documento: this.props.serieDocumento,
        numero_documento: this.props.numeroDocumento,
        id_contacto: idContacto,
        fecha_documento: this.props.fechaDocumento,
        fecha_venc_documento: this.props.fechaVencimiento,
      }
    }).then(response => {
        this.setState({
          idAsiento: response.data.id,
          lineas: [],
        });
    }).catch(e => {
        console.log(e);
    });
  }

  cerrarAsiento = (id_temp_asiento, guardar) => {
    // notifica si el asiento es guardado o descartar cambios
    axios.post('/api/v1/asientos/cerrar_asiento',{
      asiento: {
        id_asiento: id_temp_asiento,
        guardar: guardar
      }
    }).then(response => {
        this.setState({
          numeroAsiento: response.data.numero_asiento,
          lineas: []
        })
    }).catch(e => {
        console.log(e);
    });
  }

  createLogAsiento = () => {
    axios.post('/api/v1/log_asientos',{
      log_asiento: {
        numero_asiento: this.state.numeroAsiento,
        fecha: this.props.fecha,
        concepto: this.props.concepto,
        id_empresa: this.props.idEmpresa,
        id_usuario: this.props.idUsuario,
        accion: 0
      }
    }).then(response => {
      this.setState({idLogAsiento: response.data.id});
    }).catch(e => {
      console.log(e);
    });
  }

  addLineaAsiento = (debe, haber, idCentroCostos, nombreCentroCostos,
    idProyecto, nombreProyecto, idLocal, nombreLocal) => {

    const monedaAsiento = this.props.moneda
    const monedaLinea = this.state.selectedMonedaOption

    const cotizacionAsiento = this.props.cotizacionAsiento
    const cotizacionLinea = this.state.cotizacion

    const contactoAsiento = this.props.contactoAsiento
    const contactoLinea = this.state.selectedContactoOption

    let debeOriginal = debe
    let haberOriginal = haber

    let id_moneda = 0
    let cotizacion = 0
    let nombreMoneda = ""

    let id_contacto = 0
    let nombreContacto = ""

    if(monedaLinea) {
      id_moneda = monedaLinea.value.id
      nombreMoneda = monedaLinea.value.nombre
      cotizacion = cotizacionLinea
    } else {
      id_moneda = monedaAsiento.value.id
      nombreMoneda = monedaAsiento.value.nombre
      cotizacion = cotizacionAsiento
    }

    if(cotizacion == 0) { // no hay cotizacion, se asume moneda local
      debe = debeOriginal
      haber = haberOriginal
    } else {
      if(debeOriginal != null) {
        debe = debeOriginal * cotizacion
      }
      if(haberOriginal != null) {
        haber = haberOriginal * cotizacion
      }
    }

    if(contactoLinea) {
      id_contacto = contactoLinea.value.id
      nombreContacto = contactoLinea.value.nombre
    } else {
      id_contacto = contactoAsiento.value.id
      nombreContacto = contactoAsiento.value.nombre
    }

    axios.post('/api/v1/temp_lineas_asientos',{
      temp_lineas_asiento: {
        id_temp_asiento: this.state.idAsiento,
        debe: debe,
        haber: haber,
        debe_original: debeOriginal,
        haber_original: haberOriginal,
        concepto: this.state.concepto,
        id_cuenta: this.state.selectedCuentaOption.value.id,
        id_centro_costos: idCentroCostos,
        id_proyecto: idProyecto,
        id_local: idLocal,
        id_contacto: id_contacto,
        id_moneda: id_moneda,
        cotizacion: cotizacion,
        fecha: this.props.fecha,
      }
    }).then(response => {
      this.setState({selectedCentroCostosOption: null,
        selectedProyectoOption: null,
        selectedLocalOption: null})

      const idLineaAsiento = response.data.id
      const cuenta = this.state.selectedCuentaOption.value.codigo + ' - ' + this.state.selectedCuentaOption.value.nombre
      const debe = this.state.debe
      const haber = this.state.haber
      const concepto = this.state.concepto
      const centroCostos = nombreCentroCostos
      const proyecto = nombreProyecto
      const local = nombreLocal

      this.addItem(idLineaAsiento, cuenta, debe, haber, concepto, centroCostos, proyecto,
        local, nombreContacto, nombreMoneda, cotizacion)
    }).catch(e => {
      console.log(e);
    });
  }

  deleteLineaAsiento = (id) => {
    axios.delete(`/api/v1/temp_lineas_asientos/${id}`)
       .then(res => {
         console.log(res);
         console.log(res.data);
       })
  }

/////////////////////////////// RENDERS ////////////////////////////////////////

  renderTableHeader() {
    let header = ['Cuenta', 'Debe', 'Haber', 'Concepto', 'C.Costos', 'Proyecto',
      'Local', 'Contacto', 'Moneda', 'Cotizacion', '']
    return header.map((key, index) => {
       return <th key={index}>{key}</th>
    })
  }

  renderTableData() {
    return this.state.lineas.map((x, index) => {
      const {idLineaAsiento, nombreCuenta, debe, haber, concepto, centroCostos,
        proyecto, local, contacto, moneda, cotizacion } = x //destructuring
      x.index = index
      return (
        <tr key={idLineaAsiento}>
          <td>{nombreCuenta}</td>
          <td>{debe}</td>
          <td>{haber}</td>
          <td>{concepto}</td>
          <td>{centroCostos}</td>
          <td>{proyecto}</td>
          <td>{local}</td>
          <td>{contacto}</td>
          <td>{moneda}</td>
          <td>{cotizacion}</td>
          <td><div align="center"><Button variant="secondary" size="sm" onClick={() => this.handleDelete(idLineaAsiento)}>X</Button></div></td>
        </tr>
      )
    })
  }

  renderModalLinea() {
    const { registro } = this.state;
    return (
      <Modal

        show={this.state.showModalLinea}
        onHide={this.handleCloseModalLinea} >

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Cuenta</label>
                <div className="row">
                  <div className="col-md-12">
                    <Select value={this.state.selectedCuentaOption} onChange={this.handleChangeCuenta}
                      options={this.state.cuentasOptions} placeholder={"Selecione Cuenta"} />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
              <label>Contacto</label>
              <Select value={this.state.selectedContactoOption} onChange={this.handleChangeContacto}
                options={this.state.contactosOptions} placeholder={"Selecione Contacto"} />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Moneda</label>
                <Select value={this.state.selectedMonedaOption} onChange={this.handleChangeMoneda}
                  options={this.state.monedasOptions} placeholder={"Seleccione Moneda"} />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>Cotizacion</label>
                <input type="text" name="cotizacion" value={this.state.cotizacion}
                  onChange={this.handleChange} className="form-control" />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
              <label>Debe</label>
                <input type="text" name="debe" value={this.state.debe}
                  onChange={this.handleChange} className="form-control" />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
              <label>Haber</label>
                <input type="text" name="haber" value={this.state.haber}
                  onChange={this.handleChange} className="form-control" />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
              <label>Concepto</label>
              <input type="text" name="concepto" value={this.state.concepto}
                onChange={this.handleChange} className="form-control" />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
              <label>Centro de Costos</label>
              <Select value={this.state.selectedCentroCostosOption} onChange={this.handleChangeCentroCostos}
                isDisabled={this.state.disableCentroCostos}
                options={this.state.centrosCostosOptions} placeholder={"Seleccione Centro de Costos"} />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
              <label>Proyecto</label>
              <Select value={this.state.selectedProyectoOption} onChange={this.handleChangeProyecto}
                options={this.state.proyectosOptions} placeholder={"Seleccione Proyecto"} />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
              <label>Local</label>
              <Select value={this.state.selectedLocalOption} onChange={this.handleChangeLocal}
                options={this.state.localesOptions} placeholder={"Selecione Local"} />
              </div>
            </div>
          </div>

          <Button variant="secondary" onClick={this.handleCloseModalLinea}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={this.handleSaveLinea}>
            Guardar Cambios
         </Button>

      </Modal>
    )
  }

  renderModalCierreAsiento() {
    const errores = this.state.textosError

    const error = "El asiento no cierra: "

    const totalDebe = "Debe: " + this.state.totalDebeAsiento
    const totalHaber = "Haber: " + this.state.totalHaberAsiento

    return (
      <Modal
        show={this.state.showModalCierreAsiento}
        onHide={this.handleCloseModalCierreAsiento} >

        <div>
          <h5>{error}</h5>
          <h5>{totalDebe}</h5>
          <h5>{totalHaber}</h5>
        </div>
        <Button variant="secondary" onClick={this.handleCloseModalCierreAsiento}>
        Cerrar
        </Button>

      </Modal>
    )
  }

  renderModalError() {
    const errores = this.state.textosError

    return (
      <Modal
        show={this.state.showModalError}
        onHide={this.handleCloseModalError} >

        <div>
          {errores.map((error, index) =>
          <div key={error + index}>
              <h5>{error}</h5>
              <hr/>
          </div>
          )}
        </div>

        <Button variant="secondary" onClick={this.handleCloseModalError}>
        Cerrar
        </Button>

      </Modal>
    )
  }

  renderModalErrorLinea() {
    const errores = this.state.textosErroresLinea
    return (
      <Modal
        show={this.state.showModalErroresLinea}
        onHide={this.handleCloseModalErroresLinea} >

        <div>
          {errores.map((error, index) =>
          <div key={error + index}>
              <h5>{error}</h5>
              <hr/>
          </div>
          )}
        </div>

        <Button variant="secondary" onClick={this.handleCloseModalErroresLinea}>
        Cerrar
        </Button>

      </Modal>
    )
  }

  render() {
    return (
      <div>
        <Button variant="primary" onClick={this.handleAgregarLinea}>Agregar Linea</Button>

        {this.renderModalError()}
        {this.renderModalLinea()}
        {this.renderModalErrorLinea()}

        <Table striped bordered size="sm">
          <tbody>
            <tr>{this.renderTableHeader()}</tr>
            {this.renderTableData()}
          </tbody>
        </Table>

        {this.renderModalCierreAsiento()}

        <Button variant="secondary" onClick={this.handleCerrarSinGuardarAsiento}>Cerrar sin Guardar</Button>
        <Button variant="primary" onClick={this.handleSaveAsiento}>Guardar Asiento</Button>
      </div>
    )
  }
}

export default LineasAsiento
