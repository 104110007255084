import React from "react"
import Select from 'react-select';
import axios from 'axios';
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert';

import Datetime from 'react-datetime'
import Moment from 'react-moment';
//import 'moment-timezone';
import '../styles/Application.css';
import '../styles/react-datetime.css';

import LineasAsiento from './LineasAsiento'
import FormErrors from './FormErrors'
import Modal from './Modal'

class CabezalAsiento extends React.Component {

  constructor(props) {
    super(props)

    let monedas = []
    const tmpMonedas = props.dataMonedas.slice();
    tmpMonedas.map((x, index) => {
      let registro = { value: x, label: x.nombre }
      monedas.push(registro)
    })

    let tiposAsientos = []
    const tmpTiposAsientos = props.dataTiposAsientos.slice();
    tmpTiposAsientos.map((x, index) => {
      let registro = { value: x, label: x.nombre }
      tiposAsientos.push(registro)
    })

    let tiposDocumentos = []
    const tmpTiposDocumentos = props.dataTiposDocumentos.slice();
    tmpTiposDocumentos.map((x, index) => {
      let registro = { value: x, label: x.nombre }
      tiposDocumentos.push(registro)
    })

    let contactos = []
    const tmpContactos = props.dataContactos.slice();
    tmpContactos.map((x, index) => {
      let registro = { value: x, label: x.rut + ' - ' + x.nombre  }
      contactos.push(registro)
    })

    this.initState(monedas, tiposAsientos, tiposDocumentos, contactos)
  }

  initState(monedas, tiposAsientos, tiposDocumentos, contactos) {
    const state = {
      formErrors: {dia: '', mes: '', anio: ''},
      dia: this.props.defaultDay,
      mes: this.props.defaultMonth,
      anio: this.props.defaultYear,
      fecha: this.props.defaultYear+"-"+this.props.defaultMonth+"-"+this.props.defaultDay,
      mesDefaultWarning: false,
      anioDefaultWarning: false,
      fechaDocumento: new Date(),
      fechaVencimiento: new Date(),
      selectedTipoAsientoOption: null,
      selectedMonedaOption: null,
      selectedTipoDocumentoOption: null,
      selectedContactoOption: null,
      monedasOptions: monedas,
      tiposAsientosOptions: tiposAsientos,
      tiposDocumentosOptions: tiposDocumentos,
      contactosOptions: contactos,
      cotizacion: '',
      concepto: '',
      serieDocumento: '',
      numeroDocumento: '',
      numeroAsiento: this.props.numeroAsiento + 1,
      monedaLocal: this.props.monedaLocal,
    };
    this.state = state;
  }

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value});
  }

  handleKeyPress = (e) => {
    const target = e.target;
    console.log("target", target)
  }

  handleChangeDia = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value});
    this.validateField(name, value, false);
    // refresca cotizacion
    const moneda = this.state.selectedMonedaOption.value.id;
    const fecha = new Date(this.state.anio+"-"+this.state.mes+"-"+value)

    if(moneda === 1) {
      this.setState({cotizacion: 1})
    } else {
      this.getCotizacion(fecha, moneda)
    }
  }

  handleChangeMes = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value});
    this.validateField(name, value, false);
    // refresca cotizacion
    const moneda = this.state.selectedMonedaOption.value.id;
    const fecha = new Date(this.state.anio + "-" + value + "-" + this.state.dia)
    this.getCotizacion(fecha, moneda)
  }

  handleChangeAnio = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value});
    this.validateField(name, value, false);
    // refresca cotizacion
    const moneda = this.state.selectedMonedaOption.value.id;
    const fecha = new Date(value + "-" + this.state.mes + "-" + this.state.dia)

    if(moneda === 1) {
      this.setState({cotizacion: 1})
    } else {
      this.getCotizacion(fecha, moneda)
    }
  }

  handleChangeFechaDocumento = (fecha) => {
    this.setState({
      fechaDocumento: fecha._d
    });
  }

  handleChangeFechaVencimiento = (fecha) => {
    this.setState({
      fechaVencimiento: fecha._d
    });
  }

  handleChangeMoneda = (selectedMonedaOption) => {
    this.setState({
      selectedMonedaOption: selectedMonedaOption
    });
    const fecha = new Date(this.state.anio+"-"+this.state.mes+"-"+this.state.dia)
    const moneda = selectedMonedaOption.value.id;

    if(moneda === 1) {
      this.setState({cotizacion: 1})
    } else {
      this.getCotizacion(fecha, moneda)
    }

  }

  getCotizacion = (fecha, moneda) => {
    const strFecha = fecha.getFullYear() + "-" + (fecha.getMonth() + 1) + "-" + fecha.getDate()
    axios({
      method: 'get',
      url: '/api/v1/monedas/get_cotizacion?moneda='+moneda+'&fecha='+strFecha,
      responseType: 'json'
    }).then(response => {
        this.setState({cotizacion: response.data.fiscal})
    }).catch(e => {
        console.log(e);
    });
  }

  handleChangeTipoAsiento = (selectedTipoAsientoOption) => {
    this.setState({ selectedTipoAsientoOption });
  }

  handleChangeTipoDocumento = (selectedTipoDocumentoOption) => {
    this.setState({ selectedTipoDocumentoOption });
  }

  handleChangeContacto = (selectedContactoOption) => {
    this.setState({
      selectedContactoOption: selectedContactoOption
    });
  }

////////////////////////// VALIDACION ///////////////////////////

    validateField(fieldName, value, resultBlur) {
      //console.log("validateField");
      let fieldValidationErrors = this.state.formErrors;

      let diaValid = this.state.diaValid;
      let mesValid = this.state.mesValid;
      let anioValid = this.state.anioValid;

      switch(fieldName) {

        case 'dia':
          diaValid = value > 0 && value <= 31;
          fieldValidationErrors.dia = diaValid ? '' : ' is invalid';
          break;

        case 'mes':
          if(value != this.props.defaultMonth) {
            this.setState({mesDefaultWarning: true});
          }
          else {
            this.setState({mesDefaultWarning: false});
          }

          mesValid = value > 0 && value <= 12;
          fieldValidationErrors.mes = mesValid ? '' : ' is invalid';
          break;

        case 'anio':
          if(value != this.props.defaultYear) {
            this.setState({anioDefaultWarning: true});
          }
          else {
            this.setState({anioDefaultWarning: false});
          }

          anioValid = value >= this.props.anioMin && value <= this.props.anioMax;
          fieldValidationErrors.anio = anioValid ? '' : ' is invalid';
          break;

        default:
          break;
        }
      }

      validateForm() {

        this.setState({formValid:
          this.state.diaValid &&
          this.state.mesValid &&
          this.state.anioValid
          });
      }

      errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
      }

/*-------------------------------------------------------------------------*/

  render () {

    return (
      <React.Fragment>

      <div>
        <FormErrors formErrors={this.state.formErrors} />
      </div>

{/*  ROW */}
        <div className="row">

          <div className="col-md-1">
            <div className={`form-group ${this.errorClass(this.state.formErrors.dia)}`}>
              <label htmlFor="dia">Dia</label>
              <input autoFocus type="text" className="form-control"
                name="dia" value={this.state.dia} ref={this.storeRef}
                onChange={this.handleChangeDia} onKeyPress={this.handleKeyPress}
                style={{backgroundColor: '#ccff99'}}/>
            </div>
          </div>

          <div className="col-md-1">
            <div className={`form-group ${this.errorClass(this.state.formErrors.mes)}`}>
              <label htmlFor="mes">Mes</label>
              <input type="text" className="form-control"
                name="mes" value={this.state.mes} ref={this.storeRef}
                onChange={this.handleChangeMes} onKeyPress={this.handleKeyPress}
                style={{backgroundColor: '#ccff99'}}/>
            </div>
          </div>

          <div className="col-md-1">
            <div className={`form-group ${this.errorClass(this.state.formErrors.anio)}`}>
              <label htmlFor="anio">Año</label>
              <input type="text" className="form-control"
                name="anio" value={this.state.anio} ref={this.storeRef}
                onChange={this.handleChangeAnio} onKeyPress={this.handleKeyPress}
                style={{backgroundColor: '#ccff99'}}/>
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label>Moneda</label>
              <div className="row">
                <div className="col-md-12">
                  <Select
                    value={this.state.selectedMonedaOption}
                    placeholder={"Moneda"}
                    options={this.state.monedasOptions}
                    onChange={this.handleChangeMoneda}
                    onKeyPress={this.handleKeyPress}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-2">
            <div className="form-group">
              <label>Cotizacion</label>
              <div className="row">
                <div className="col-md-12">
                  <input type="text" name="cotizacion"
                    value={this.state.cotizacion}
                    onChange={this.handleChange}
                    className="form-control" />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group">
              <label>Tipo de Asiento</label>
              <div className="row">
                <div className="col-md-12">
                  <Select
                    value={this.state.selectedTipoAsientoOption}
                    onChange={this.handleChangeTipoAsiento}
                    options={this.state.tiposAsientosOptions}
                    placeholder={"Tipo de Asiento"}
                  />
                </div>
              </div>
            </div>
          </div>


        </div>

{/*  ROW (tipo de documento, serie, numero, fecha documento, fecha vencimiento ) */}

        <div className="row">

          <div className="col-md-5">
            <div className="form-group">
              <label>Tipo de Documento</label>
              <div className="row">
                <div className="col-md-12">
                  <Select
                    value={this.state.selectedTipoDocumentoOption}
                    onChange={this.handleChangeTipoDocumento}
                    options={this.state.tiposDocumentosOptions}
                    placeholder={"Tipo Documento"}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-1">
            <div className="form-group">
              <label>Serie</label>
              <div className="row">
                <div className="col-md-12">
                  <input type="text" name="serieDocumento"
                    value={this.state.serieDocumento}
                    onChange={this.handleChange}
                    className="form-control" />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-2">
            <div className="form-group">
              <label>Numero</label>
              <div className="row">
                <div className="col-md-12">
                  <input type="text" name="numeroDocumento"
                    value={this.state.numeroDocumento}
                    onChange={this.handleChange}
                    className="form-control" />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-2">
            <div className="form-group">
              <label>Fecha Documento</label>
              <Datetime
                value={this.state.fechaDocumento} name="fechaDocumento"
                closeOnSelect={true}
                ref={(x) => {this.dateTime = x}} onChange={this.handleChangeFechaDocumento}
                dateFormat={"DD/MM/YYYY"} timeFormat={false} input={true}
                inputProps={{name: 'fechaDocumento', ref: this.storeRef, onKeyPress: this.handleKeyPress}}
                />
            </div>
          </div>

          <div className="col-md-2">
            <div className="form-group">
              <label>Fecha Vencimiento</label>
              <Datetime
                value={this.state.fechaVencimiento} name="fechaVencimiento"
                closeOnSelect={false}
                ref={(x) => {this.dateTime = x}} onChange={this.handleChangeFechaVencimiento}
                dateFormat={"DD/MM/YYYY"} timeFormat={false} input={true}
                inputProps={{name: 'fechaVencimiento', ref: this.storeRef, onKeyPress: this.handleKeyPress}}
                />
            </div>
          </div>
        </div>

{/*  ROW contacto, concepto */}

        <div className="row">

          <div className="col-md-5">
            <div className="form-group">
              <label>Contacto</label>
              <div className="row">
                <div className="col-md-12">
                  <Select
                    value={this.state.selectedContactoOption}
                    onChange={this.handleChangeContacto}
                    options={this.state.contactosOptions}
                    placeholder={"Contacto"}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-7">
            <div className="form-group">
              <label>Concepto</label>
              <div className="row">
                <div className="col-md-12">
                  <input type="text" name="concepto"
                    value={this.state.concepto}
                    onChange={this.handleChange}
                    className="form-control" />
                </div>
              </div>
            </div>
          </div>
        </div>

{/*  ROW */}

        <div className="row">
          <div className="col-md-12">
            { this.props.dataCuentas.length > 0 ? <LineasAsiento
              idEmpresa = {this.props.idEmpresa}
              idUsuario = {this.props.idUsuario}
              dataCuentas = {this.props.dataCuentas}
              dataMonedas = {this.props.dataMonedas}
              dataCentrosCostos = {this.props.dataCentrosCostos}
              dataProyectos = {this.props.dataProyectos}
              dataLocales = {this.props.dataLocales}
              dataContactos = {this.props.dataContactos}
              cotizacionAsiento = { parseFloat(this.state.cotizacion) }
              concepto = {this.state.concepto}
              tipoAsiento = {this.state.selectedTipoAsientoOption}
              moneda = {this.state.selectedMonedaOption}
              numeroAsiento = {this.state.numeroAsiento}
              dia = {this.state.dia}
              mes = {this.state.mes}
              anio = {this.state.anio}
              fechaDocumento = {this.state.fechaDocumento}
              fechaVencimiento = {this.state.fechaVencimiento}
              tipoDocumento = {this.state.selectedTipoDocumentoOption}
              serieDocumento = {this.state.serieDocumento}
              numeroDocumento = {this.state.numeroDocumento}
              contactoAsiento = {this.state.selectedContactoOption}
            /> : null }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CabezalAsiento
