import React from "react"
import Alert from 'react-bootstrap/Alert';

class FormErrors extends React.Component {

	render() {
		const formErrors = this.props.formErrors;
		var m1 = '';
		var m2 = '';
		var m3 = '';

		let show = false

		//console.log(formErrors.email, formErrors.password);

		if(formErrors.dia != '') {
		  m1 = 'El dia debe ser entre 1 y 31'
			show = true
		}

		if(formErrors.mes != '') {
		  m2 = 'El mes debe ser entre 1 y 12'
			show = true
		}

		if(formErrors.anio != '') {
		  m3 = 'El año no se encuentra dentro de los parametros permitidos'
			show = true
		}

		if (show) {
			return(
				<Alert variant="danger">
				  <Alert.Heading>Se ha producido un error!</Alert.Heading>
					<div>
		  			<p>{ m1 }</p>
		  			<p>{ m2 }</p>
		  			<p>{ m3 }</p>
				  </div>
				  <hr />
				  <p className="mb-0">
				   	Digite nuevamente el valor indicado
				  </p>
			  </Alert>
			)
		} else {
			return(
				<p>

				</p>
			);
		}
	}
}

export default FormErrors
